import * as React from "react"
import { Seo } from "../components/seo"
import Layout from '../components/layout'
import "../css/page-home.scss"
import "../css/page-swipers.scss"
import { StaticImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import "swiper/css/pagination"
import "swiper/css/navigation"
import { Autoplay, Pagination, Navigation } from "swiper"
import { FaFacebook } from 'react-icons/fa'
import { FaChevronRight } from 'react-icons/fa'

const IndexPage = () => {
  return (
	<Layout>
		<main>

			<section id="homepageHero">
				<div className="container">
					<div>
						<h1>Habitat for Humanity Northwoods Wisconsin</h1>
						<h2>To bring people together to build<br /> homes, communities, and hope.</h2>
					</div>
				</div>
			</section>

			<section id="homepageCtas">
				<div className="container">
					<a href="/home-ownership-opportunities/"><div>Home Ownership Opportunities <FaChevronRight /></div></a>
					<a href="/get-involved/volunteer/"><div>Volunteer <FaChevronRight /></div></a>
					<a href="/restore/"><div>ReStore <FaChevronRight /></div></a>
					<a href="/donate/"><div>Donate <FaChevronRight /></div></a>
				</div>
			</section>

			<section id="homepageIntro">
				<div className="container">
					<div>
						<h2>About Us</h2>
						<p>
							Habitat for Humanity Northwoods Wisconsin was founded in 1996 and finished its first Habitat home in 1998. 
							Since that time, we have built over 25 homes in our service area of Oneida and Vilas counties. 
							Like all Habitat for Humanity affiliates, we rely on donations and volunteer help to fulfill our mission. 
							In addition, we have a successful Habitat Restore operation on Lincoln Street in Rhinelander which helps fund our 
							construction projects.
						</p>
					</div>
					<div className="introImages">
						<StaticImage src="../images/partners/partner-6.jpg" alt="HFH team and partners at build site" className="aboutImage" />
						<StaticImage src="../images/24th-build/IMG_9771.jpg" alt="Restore team with trucks" className="aboutImage" />
					</div>
				</div>
			</section>

			<section id="homepageConnect">
				<div className="container">
					<div className="connectGrid">
						<div className="iframeWrapper">
							<iframe title="Facebook Feed" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fnorthwoods.restore&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" width="340" height="500" scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
						</div>
						<div className="contentWrapper">
							<h2>Thank you for building with us!</h2>
							<p>
								We wish to thank all of our supporters that give of their time, services, materials and financial gifts. 
								We can all help new homeowners, in building a better future. 
								The families who partner with Habitat for Humanity, are grateful for your assistance.
							</p>
							<div className="connectLinks">
								<a href="https://www.facebook.com/northwoods.restore/" className="facebookLink" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
								<a href="/about/contact/" className="button button-secondary">Contact Us</a>
								<a href="/get-involved/volunteer/" className="button button-secondary">Get Involved</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="homepageSwiper">
			<Swiper
                spaceBetween={0}
				slidesPerView={1}
                hashNavigation={{
                    watchState: true,
                }}
                autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
                loop={true}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
				breakpoints={{
					640: {
					  slidesPerView: 2,
					},
					768: {
					  slidesPerView: 4,
					},
					1099: {
					  slidesPerView: 5,
					},
				  }}
            >
                <SwiperSlide>
					<StaticImage src="../images/phelps/IMG_9723.JPG" alt="Swiper 1" />
                </SwiperSlide>
                <SwiperSlide>
					<StaticImage src="../images/partners/partner-5.jpg" alt="Swiper 2" />
                </SwiperSlide>
                <SwiperSlide>
					<StaticImage src="../images/lac-du-flambeau/IMG_9728.JPG" alt="Swiper 3" />
                </SwiperSlide>
				<SwiperSlide>
					<StaticImage src="../images/24th-build/IMG_9788.jpg" alt="Swiper 4" />
                </SwiperSlide>
				<SwiperSlide>
					<StaticImage src="../images/24th-build/IMG_9793.jpg" alt="Swiper 5" />
                </SwiperSlide>
				<SwiperSlide>
					<StaticImage src="../images/phelps/IMG_9722.JPG" alt="Swiper 6" />
                </SwiperSlide>
				<SwiperSlide>
					<StaticImage src="../images/partners/partner-1.jpg" alt="Swiper 7" />
                </SwiperSlide>
				<SwiperSlide>
					<StaticImage src="../images/current-build/IMG_1031.jpg" alt="Swiper 8" />
                </SwiperSlide>
				<SwiperSlide>
					<StaticImage src="../images/thayer-street/thayer-street-5.jpg" alt="Swiper 9" />
                </SwiperSlide>
				<SwiperSlide>
					<StaticImage src="../images/thayer-street/thayer-street-1.jpeg" alt="Swiper 10" />
                </SwiperSlide>
				<SwiperSlide>
					<StaticImage src="../images/building/front.jpg" alt="Swiper 11" />
                </SwiperSlide>
				<SwiperSlide>
					<StaticImage src="../images/current-build/IMG_0869.jpg" alt="Swiper 12" />
                </SwiperSlide>
            </Swiper>
			</section>

		</main>
	</Layout>
  )
}
export default IndexPage

export const Head = () => (
	<Seo />
)
